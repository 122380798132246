import { createWebHistory, createRouter } from "vue-router";
import Home from "@/Home.vue";

const routes = [
    {
        path: "/order-tracker/:slug",
        name: "Home",
        component: Home,
    },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;