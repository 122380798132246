<template>
    <div class="header-purple">
        FAST DELIVERY | NO MINIMUM ORDER QUANTITY | ALL INCLUSIVE PRICING 
    </div>
    <div class="header">
        <div class="logo">
            <a href="/">
                <img src="https://teamgear.ca/cdn/shop/files/canadian_TGC_logo_240x.png?v=1707198866" alt="">
            </a>
        </div>
        <div class="tracker-text">
            <p>Order Tracking</p>
        </div>
    </div>
    <!-- <div class="content">
        Test
    </div> -->
    <div class="footer">
        CANADIAN MADE 🇨🇦 WORN WORLDWIDE 🌎
    </div>
    <!-- <div class="header">
        <div class="grey-bar">
            <div class="logo">
                <a href="/">
                    <img src="https://teamgear.ca/cdn/shop/files/canadian_TGC_logo_240x.png?v=1707198866" alt="">
                </a>
            </div>
            <div class="navigation desktop">
                <ul>
                    <li v-for="item of navigation" :key="item.url" :class="item.class">
                        <a :href="item.url">{{ item.name }}</a>
                    </li>
                </ul>
            </div>
            <div class="navigation mobile">
                <ul>
                    
                    <li class="right mobile">
                        <a v-on:click="menuNav = true">Menu</a>
                    </li>
                </ul>
            </div>
        </div>
    </div> -->
</template>

<script>


export default {
    data() {
        return {
            menuNav: false,
            navigation: [
                {
                    url: '/',
                    name: 'Home'
                },
                {
                    url: '/leagues',
                    name: 'Leagues'
                },
                {
                    url: '/tournaments',
                    name: 'Tournaments'
                },
                {
                    url: '/fall-ball',
                    name: 'Fall Ball'
                },
                {
                    url: '/become-an-umpire',
                    name: 'Become an Umpire'
                },    
                {
                    url: '/5050',
                    name: '50-50'
                },  
            ],
            secondNavigation: [
                {
                    url: '/become-a-sponsor',
                    name: 'Sponsorship'
                },
                {
                    url: '/storefront',
                    name: 'Storefront',
                },
                {
                    url: '/donate',
                    name: 'Donate'
                },
                {
                    // url: 'https://cdn.blacktiecollab.com/henningpark.com/Media Release - Caledonia Minor Hardball (OTF Final).docx',
                    url: '/trillium-foundation',
                    name: 'Trillium Foundation',
                },
                {
                    url: '/contact-us',
                    name: 'Contact Us'
                },
                
                
            ]
        }
        
    },
}
</script>
