<template>
	<div class="app">
		<Header></Header>
		<router-view />
	</div>
</template>

<script>
import Slides from './Slides.vue';
import Header from './Header.vue';

export default {
	path: '/',
	data() {
		return {
			gallery: 0,
			menuNav: false,
			
		};
	},
	mounted: function() {
		var vm = this
	},
	components: {
		Slides,
		Header
	}
	
}
</script>

<style lang="scss">
	@import './assets/scss/Reset.scss';
	@import './assets/scss/Grid.scss';
  @import './assets/scss/App.scss';
</style>

