<template>
	<div v-if="order_tracking">
		<!-- <div id="application">
			<div class="upcoming-at-the-park">
				<div class="wrapper">
					<div class="upcoming-header">
						The Best Place to Play Ball in <u>Ontario.</u>
					</div>
				</div>
			</div>
		</div> -->
		<div class="tracker">
			<h1>{{ order_tracking['Name']}}</h1>
			<!-- <p class="text-center">Week: {{ computedOrderTracker }}</p> -->
			<!-- {{ order_tracking }} -->
			<div class="tracker-setup">
                <div class="bar" v-for="n in 4" :key="n" :style="{ width: (25 * n) + '%' }" :class="n <= computedOrderTracker ? 'bar-' + n : 'bar-inactive'">
					{{ barText(n) }}
				</div>
            </div>
			<p>Estimated completion times are not guaranteed. This estimation is based on the date your order was sent to production and does not include any shipping times.</p>
			<p>If you have questions, let us know by email: contact@teamgear.ca</p>
			<br><br><br><br><br><br>
			<!-- {{ order_tracking}} -->
		</div>
	</div>
</template>

<script>
import _ from 'lodash'
import axios from 'axios'

export default {
	data() {
		return {
			test: null,
			order_tracking: null
		};
	},
	components: {

	},
	watch: {
		
	},
	mounted: function() {
       var vm = this;
       var slug = this.$route.params.slug;
       if (slug) {
           axios.get(`https://api.slopitchcentral.com/airtable/grab-customer/${slug}`)
           .then(function (response) {
               console.log('response', response.data);
               vm.order_tracking = response.data;
           })
           .catch(function (error) {
               console.error('Error fetching order tracking:', error);
           });
       } else {
           console.error('Slug is undefined');
       }
    },
	computed: {
		computedOrderTracker: function() {
			console.log('this')
			console.log('this.order_tracking', this.order_tracking)
			console.log("this.order_tracking['Last Updated']", this.order_tracking)
			if (this.order_tracking && this.order_tracking['Last Update']) {
				const lastUpdatedDate = new Date(this.order_tracking['Last Update']);
				console.log('lastUpdatedDate', lastUpdatedDate);
				const currentDate = new Date();
				console.log('currentDate', currentDate);
				const millisecondsPerWeek = 1000 * 60 * 60 * 24 * 7;
				
				const weeksBetween = Math.floor((currentDate - lastUpdatedDate) / millisecondsPerWeek);
				return weeksBetween;
			}
			return 0; // Return 0 if there's no 'Last Updated' date
		}
	},
	methods: {
		barText(n) {
            switch(n) {
                case 1: return 'Sending to Production';
                case 2: return 'Printing & Preparation';
                case 3: return 'Pressing, Cutting';
                case 4: return 'Sewing & 2-Pass Quality Assurance';
                default: return ''; // Default case if needed
            }
        }
	}
}
</script>

<style lang="scss">
    .tracker {
        display: flex; /* Use flexbox to align children */
        flex-direction: column; /* Stack children vertically */
        align-items: center; /* Center children horizontally */
        justify-content: center; /* Center children vertically */
        max-width: 900px;
        margin: 0 auto;
        margin-top: 50px; /* Adjust or remove if necessary */
        height: calc(100vh - 270px); /* Subtract header height */
    }

    .tracker p {
        font-size: 14px;
        font-weight: 700;
        color: #999;
        margin-top: 20px;
    }

    .tracker h1 {
        font-size: 48px;
        margin-bottom: 20px;
        text-align: center;
        font-family: 'Oswald', sans-serif;
    }

    .tracker-setup {
        display: flex; /* Ensures horizontal layout */
        justify-content: space-between; /* Ensures spacing between bars */
        margin-top: 20px;
        overflow: hidden;
        border-radius: 10px;
        width: 100%; /* Ensure it takes full width of the tracker */
    }

    .bar {
        display: flex; /* Use flexbox to align text */
        align-items: center; /* Center text vertically */
        justify-content: center; /* Center text horizontally */
        height: 50px;
        color: #fff;
        font-family: 'Oswald', sans-serif;
        text-transform: uppercase;
        font-weight: 700;
        background-color: lightgray; /* Default color for inactive bars */
        transition: width 0.3s ease;
        text-align: center; /* Ensures text is centered if flexbox fails */
    }

    .bar-inactive {
        background-color: lightgray; /* Color for inactive bars */
    }

    .bar-1 { background-color: #ab7df8; width: 35%; }
    .bar-2 { background-color: #813df4; width: 60%; }
    .bar-3 { background-color: #5c1fc2; width: 75%; }
    .bar-4 { background-color: #3a147a; width: 100%; }
</style>